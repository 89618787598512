<template>
  <div v-if="exameShow">
    <!-- <v-dialog v-model="dialog" persistent max-width="1024"> -->
      <v-form lazy-validation v-on:submit.prevent="onSubmit" ref="form">
        <v-card outlined>
          <v-card-title v-if="!titleOff">
            <span class="headline primary--text">Adicionar Novo Adendo</span>
          </v-card-title>
          <v-card-text v-if="!headersOff">
            <v-row dense>
              <v-col cols="12" md="2">
                <v-tooltip open-delay="800" top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-on="!isDateToday(exameShow.data) ? on : false"
                      v-bind="attrs"
                    >{{ formatDataConsulta(exameShow.data) }}</span>
                  </template>
                  <span> {{ formatDataConsultaDisplay(exameShow.data) }} </span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" md="4">{{ $_.startCase(exameShow.solicitacao.paciente.nome.toLowerCase()) }}</v-col>
              <v-col cols="12" md="4">{{ exameShow.solicitacao.setor.unidade.nome }}</v-col>
              <v-col cols="12" md="2" class="text-center">
                <v-chip dark small label style="width: 125px" class="justify-center"
                  :color="statusColor(`${exameShow.statusExame.id}`) + ' white--text'"
                >
                  <span>{{ exameShow.statusExame.descricao }}</span>
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="pb-0">
            <v-row dense class="mr-1">
              <v-col cols="12" md="12" v-if="modeloFormularios">
                <v-chip-group
                  active-class="blue blue--text text--accent-4"
                  v-model="modeloFormulario"
                  mandatory
                  column
                >
                  <v-chip v-for="(modeloFormulario, index) in modeloFormularios" :key="index"
                    :value="modeloFormulario"
                    outlined
                    label
                    :ripple="false"
                  >
                    {{ modeloFormulario.nome }}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12" md="12">
                <formulario-create-edit v-if="existsFormularioExameAdendo" ref="formView"
                  :key="formularioExameAdendo.formulario._id + '_' + formularioExameAdendo.formulario.nome"
                  :formularioParam="formularioExameAdendo.formulario"
                  :storageNameParam="exameShow.solicitacao.setor.unidade.storage"
                  :saveBottonOff="true"
                  :autoSaveUpdate="false"
                  :formularioRascunho="false"
                  v-model="executeSaveUpdateForms"
                  @confirmSaveForms="returnSaveUpdateForms"
                ></formulario-create-edit>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pt-0 pr-4">
            <v-spacer></v-spacer>
            <v-btn color="error" class="text-capitalize" outlined v-if="!cancelBottonOff" @click="close">Cancelar</v-btn>
            <v-btn color="primary" class="text-capitalize" outlined type="submit">Adicionar Adendo</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    <!-- </v-dialog> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import FormularioCreateEdit from '../../components/forms/FormularioCreateEdit.vue';

export default {
  name: "exame-adendo-create-edit",
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    titleOff: {
      type: Boolean,
      default: false,
    },
    headersOff: {
      type: Boolean,
      default: false,
    },
    cancelBottonOff: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      exame: null,
      exameShow: null,
      modeloFormulario: null,
      modeloFormularios: [],
      executeSaveUpdateForms: false,

      formularioExameAdendo: {
        formulario: {}
      },
    };
  },
  watch: {
    value(value) {
      this.exame = value;
    },
    exame(value) {
      this.$emit("input", value);
      if (value) {
        this.getModeloFormulaios()
        this.getExameFull()
        this.dialog = true
      }
    },
    modeloFormulario() {
      this.changeModeloFormulario()
    }
  },
  components: {
    FormularioCreateEdit
  },
  computed: {
    existsFormularioExameAdendo() {
      return this.formularioExameAdendo?.formulario?.modeloFormulario?._id
    }
  },
  methods: {
    ...mapActions(["setAlerta"]),
    isDateToday(date) {
      return moment().format("DD/MM/YYYY") == moment(date).format("DD/MM/YYYY");
    },
    formatDataConsulta(date) {
      let dateItem = moment(date, "DD/MM/YYYY HH:mm");
      let dateItemAux = dateItem.format("YYYY-MM-DD");

      let dateNow = moment();
      let dateNowAux = dateNow.format("YYYY-MM-DD");

      if (dateItemAux == dateNowAux) {
        return dateItem.locale("pt-br").format("HH:mm");
      } else {
        return dateItem.locale("pt-br").format("DD MMM");
      }
    },
    formatDataConsultaDisplay(date) {
      let yearNow = moment().format("YYYY");
      let yearItem = moment(date, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD");

      if (yearNow === yearItem)
        return moment(date, "DD/MM/YYYY HH:mm").locale("pt-br").format("HH:mm");
      else
        return moment(date, "DD/MM/YYYY HH:mm")
          .locale("pt-br")
          .format("MMM YYYY HH:mm");
    },
    statusColor(status) {
      return status == "1"
        ? "green"
        : status == "2"
        ? "primary"
        : status == "3"
        ? "red"
        : status == "4"
        ? "blue"
        : status == "5"
        ? "orange"
        : "";
    },
    async getExameFull() {
      const self = this
      await self.$http.get(`/exame/${this.exame.id}`)
        .then(response => {
          self.exameShow = response.data
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Não foi possível buscar o exame!",
          })
        })
    },
    // Busca os modeloFormularios permitidos para a unidade e ativos
    async getModeloFormulaios() {
      const self = this;
      const CODIGO_TIPO_FORMULARIO = "LAUDO_ADENDO"
      let tipoFormulario = await self.$http.get(`/tipoForms/findByCodigoEquals/${CODIGO_TIPO_FORMULARIO}`).then(response => response.data)
      
      let url
      if (tipoFormulario?._id) {
        url = `/modeloForms/findByTipoFormularioIdPermitidos/${tipoFormulario._id}`
        let param = `?`
        if (self.exame?.solicitacao?.setor?.unidade?.id) {
          url += `${param}unidadeId=${self.exame.solicitacao.setor.unidade.id}`
          param = `&`
        }
      } else {
        url = `/modeloForms?codigoTipoFormulario=${CODIGO_TIPO_FORMULARIO}`
      }
      self.modeloFormularios = await self.$http.get(url)
        .then(response => {
          let modelos = response?.data?.content || response?.data
          if (Array.isArray(modelos) && modelos.length > 0) {
            let modelosAtivos = modelos.filter(modelo => modelo.situacao)
            return modelosAtivos
          }
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Não foram encontrados modelos!",
          })
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Não foi possível os modelos de formulario!",
          })
        })
    },
    changeModeloFormulario() {
      // Realiza a mudança do formularioModelo
      this.formularioExameAdendo = { ...this.formularioExameAdendo, tipoFormulario: this.modeloFormulario.tipoFormulario }
      
      const formularioModelo = { ...this.modeloFormulario, _id: null, modeloFormulario: { _id: this.modeloFormulario._id }}
      this.formularioExameAdendo = { ...this.formularioExameAdendo, formulario: JSON.parse(JSON.stringify(formularioModelo))}
      return
    },
    async returnSaveUpdateForms(formulario) {     
      if (formulario && formulario._id) {
        console.log("formularioSavedUpdated");
        console.log(formulario);

        // Function that get the last formularioExame in chain
        let setFormularioExame = async (formularioExame, newFormularioExame) => {
          if (formularioExame?.formularioExamePai?.id) {
            setFormularioExame(formularioExame.formularioExamePai, newFormularioExame)
          } else {
            formularioExame.formularioExamePai = newFormularioExame
          } 
          return formularioExame
        }
        // Charge the new formularioExameAdendo
        this.formularioExameAdendo = { ...this.formularioExameAdendo, formulario: formulario }
        if (this.exameShow?.formularioExameLaudo) {
          await setFormularioExame(this.exameShow.formularioExameLaudo, this.formularioExameAdendo)
          console.log("formularioExameAdendo")
          console.log(this.formularioExameAdendo)
        }
        this.saveUpdate()
      }
    },
    saveUpdate() {
      const self = this

      if (self.exameShow.id) {
        
        let url = `/exame/changeStatus/${self.exameShow.statusExame.id}`
        self.$http.put(url, self.exameShow)
          .then(async response => {
            self.$emit('objectReturn', await self.$http.get(`/exame/${response.data.id}`).then(response => response.data))
            self.setAlerta( {
              tipoAlerta : 'success',
              textAlerta : 'Adendo incluído com sucesso',
            })
            this.close();
          })
          .catch(async () => {
            // Delete the created formulario
            await self.$http.delete(`/forms/${self.formularioExameAdendo.formulario._id}`)  

            self.setAlerta({
              tipoAlerta : 'error',
              textAlerta : 'Erro ao alterar exame',
            })
          })
      }
    },
    close() {
      this.exame = null
      this.exameShow = null
      this.dialog = false

      this.formularioExameAdendo = {
        formulario: {}
      }
    },
    onSubmit() {
      const self = this
      
      if(!self.$refs.form.validate() || !self.$refs.formView.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: 'error',
          textAlerta: 'Favor preencher todos os campos'
        })
        return
      }

      self.executeSaveUpdateForms = true
    }
  },
  created() {
    this.getModeloFormulaios()
  }
};
</script>